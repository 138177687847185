import {
  REMOVE_HABITAT_ANIMAL,
  SET_HABITAT,
  SET_HABITAT_LIKED,
  SET_HABITAT_PROPS,
  SET_HABITAT_STREAM_STARTED,
  SET_SELECTED_CAMERA,
  UNSET_HABITAT,
  UPDATE_AVAILABLE_CAMERAS,
  UPSERT_HABITAT_ANIMAL,
} from '../types';

const initialState = {};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case SET_HABITAT: {
      const { habitat } = payload;
      return {
        ...initialState,
        ...habitat,
      };
    }

    case UNSET_HABITAT: {
      return initialState;
    }

    case SET_HABITAT_LIKED: {
      const { isLiked } = payload;
      return { ...state, isLiked };
    }

    case SET_HABITAT_PROPS: {
      return {
        ...state,
        ...payload,
      };
    }

    case SET_HABITAT_STREAM_STARTED: {
      const { streamStarted } = payload;
      return { ...state, streamStarted };
    }

    case SET_SELECTED_CAMERA: {
      const { camera } = payload;
      return { ...state, selectedCamera: camera };
    }

    case UPDATE_AVAILABLE_CAMERAS: {
      const { availableCameras } = payload;
      return {
        ...state,
        cameras: availableCameras,
        selectedCamera: availableCameras.some(({ _id }) => _id === state.selectedCamera?._id)
          ? state.selectedCamera
          : availableCameras[0],
      };
    }

    case UPSERT_HABITAT_ANIMAL: {
      const { animal } = payload;
      const oldIndex = state.animals.findIndex(({ _id }) => _id === animal._id);

      if (oldIndex >= 0) {
        if (animal.index !== oldIndex) {
          const animals = [...state.animals];
          animals.splice(oldIndex, 1);
          animals.splice(animal.index, 0, animal);

          return {
            ...state,
            animals,
          };
        }
        return { ...state, animals: state.animals.map((a) => (a._id === animal._id ? animal : a)) };
      }

      return { ...state, animals: [...state.animals, animal] };
    }

    case REMOVE_HABITAT_ANIMAL: {
      const { animalId } = payload;
      return { ...state, animals: state.animals.filter(({ _id }) => _id !== animalId) };
    }

    default: {
      return state;
    }
  }
};
