import Header from 'Components/Header';
import { Box } from 'grommet';
import { Route, Router } from 'preact-router';

import AdminAnnouncements from '@/routes/adminAnnouncements';
import CameraInfo from '@/routes/adminCameraInfo';
import CameraSettings from '@/routes/adminCameraSettings';
import AdminConfigurations from '@/routes/adminConfigurations';
import Habitats from '@/routes/adminHabitats';
import AdminOnlineUsersInfo from '@/routes/adminOnlineUsersInfo';
import Partners from '@/routes/adminPartners';
import AdminPlaylists from '@/routes/adminPlaylists';
import TestNewModelsForAiKeeper from '@/routes/adminTestNewModelsForAiKeeper';
import Users from '@/routes/adminUsers';
import Reports from '@/routes/reports';

const AdminRouter = () => (
  <Box fill width={{ max: 'var(--maxWidth)', min: '350px' }} margin={{ horizontal: 'auto' }}>
    <Header />
    <Router>
      <Route path="/admin/users" component={Users} />
      <Route path="/admin/habitats" component={Habitats} />
      <Route path="/admin/partners" component={Partners} />
      <Route path="/admin/reports" component={Reports} />
      <Route path="/admin/camera/info" component={CameraInfo} />
      <Route path="/admin/camera/settings" component={CameraSettings} />
      <Route path="/admin/configurations" component={AdminConfigurations} />
      <Route path="/admin/online-users-stats" component={AdminOnlineUsersInfo} />
      <Route path="/admin/playlists" component={AdminPlaylists} />
      <Route path="/admin/test-new-models-for-ai-keeper" component={TestNewModelsForAiKeeper} />
      <Route path="/admin/announcements" component={AdminAnnouncements} />
    </Router>
  </Box>
);

export default AdminRouter;
