import { route } from 'preact-router';
import urlJoin from 'url-join';

import { getHomeURL } from '@/helpers';
import { unsetUserData } from '@/redux/slices/user/actions';
import store from '@/redux/store';

const baseUrl = '/api';

export const API_BASE_URL = baseUrl;

/**
 * Builds a URL from the base URL and the given parts
 *
 * @param  {...string} args
 * @returns {string}
 */
export const buildURL = (...args) => urlJoin(baseUrl, ...args);

export class RequestError extends Error {
  constructor(statusCode, body) {
    super(`Request failed with status code: ${statusCode}`);
    this.statusCode = statusCode;
    this.body = body;
  }
}

/**
 * @param {string} path
 */
export const authRedirect = (path) => {
  try {
    if (path) {
      route(`${path}${path === '/talk-schedule' ? window.location.search : ''}`, true);
    } else if (localStorage.getItem('returningUser')) {
      route('/login', true);
    } else {
      route(getHomeURL(), true);
    }
  } catch (err) {
    console.warn('Local storage is not available.');
    route(getHomeURL(), true);
  }
};

/**
 * @template [T=any]
 * @param {Response} response
 * @returns {Promise<T>}
 */
export const handleResponse = async (response) => {
  if (response.status === 401) {
    store.dispatch(unsetUserData());
    authRedirect();
  }

  if (!response.ok) {
    throw new RequestError(response.status, await response.json());
  }

  if (response.status === 204 || response.status === 201) {
    return undefined;
  }

  return response.json();
};

/**
 * @template [R=any]
 * @template [D=any]
 * @param {string} url
 * @param {D} [data]
 * @returns {Promise<R>}
 */
export const post = async (url, data) => {
  const res = await fetch(url, {
    method: 'POST',
    body: data != null ? JSON.stringify(data) : undefined,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'include',
  });

  return handleResponse(res);
};

/**
 * @template [R=any]
 * @template [D=any]
 * @param {string} url
 * @param {D} [data]
 * @returns {Promise<R>}
 */
export const patch = async (url, data = {}) => {
  const res = await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'include',
  });

  return handleResponse(res);
};

/**
 * @template [R=any]
 * @template [D=any]
 * @param {string} url
 * @param {D} [data]
 * @returns {Promise<R>}
 */
export const callDelete = async (url, data) => {
  const res = await fetch(url, {
    method: 'DELETE',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'include',
  });

  return handleResponse(res);
};

/**
 * @template [R=any]
 * @param {string} url
 * @returns {Promise<R>}
 */
export const get = async (url) => {
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
  });

  return handleResponse(res);
};

/**
 * @template [R=any]
 * @template [D=any]
 * @param {string} url
 * @param {D} [data]
 * @returns {Promise<R>}
 */
export const put = async (url, data = {}) => {
  const res = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'include',
  });

  return handleResponse(res);
};

/**
 * Fetches the partner MPLink.
 * @returns {Promise<string | null>} A promise that resolves with the MPLink string or null in case of an error.
 */
export const partnerMPLinkFetcher = async () => {
  try {
    const url = buildURL('/zoos/partnerMPLink');
    const data = await get(url);
    return data.mpLink;
  } catch (error) {
    console.error('Error fetching partner MPLink:', error);
    return null;
  }
};
