import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'preact/hooks';
import OneSignal from 'react-onesignal';
import { useDispatch } from 'react-redux';

import { postUserMarketingOptInMutationFn } from '@/queries/users';
import { useSelector } from '@/redux/helper';
import { setUserData } from '@/redux/slices/user/actions';

import { UserNotificationTag } from './types';

export const useInitOneSignal = () => {
  useEffect(() => {
    OneSignal.init({ appId: process.env.PREACT_APP_ONE_SIGNAL_APP_ID! })
      .then(async () => {
        console.log('One Signal started');
        // Remove old One Signal registration since it is conflicting with the preact-cli service worker
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistrations().then((registrations) => {
            registrations.forEach((registration) => {
              if (registration.active?.scriptURL.startsWith(`${window.location.origin}/OneSignalSDKWorker.js`)) {
                registration.unregister();
              }
            });
          });
        }
      })
      .catch((e) => {
        console.error('Error starting One Signal', e);
      });
  }, []);
};

export const useUpdatePushNotificationData = () => {
  const dispatch = useDispatch();
  const sessionChecked = useSelector((state) => state.user.sessionChecked);
  const userId = useSelector((state) => state.user.userId);
  const email = useSelector((state) => state.user.email);
  const { mutate: updateMarketingOptIn } = useMutation({
    mutationFn: postUserMarketingOptInMutationFn,
    onSuccess: (data) => {
      if (data?.user) {
        dispatch(setUserData(data.user));
      }
    },
  });

  useEffect(() => {
    if (!sessionChecked) {
      return;
    }

    if (userId) {
      OneSignal.login(userId);
    } else {
      OneSignal.logout();
    }
  }, [sessionChecked, userId]);

  useEffect(() => {
    if (sessionChecked && email) {
      OneSignal.User.addEmail(email);
    }
  }, [sessionChecked, email]);

  // Sync OneSignal tags to backend when user grants permission
  useEffect(() => {
    const handler = (granted: boolean) => {
      if (granted) {
        const notificationTags = OneSignal.User.getTags() as Record<UserNotificationTag, string>;
        updateMarketingOptIn({ notificationTags });
      }
    };
    OneSignal.Notifications.addEventListener('permissionChange', handler);
    return () => {
      OneSignal.Notifications.removeEventListener('permissionChange', handler);
    };
  }, [updateMarketingOptIn]);
};

export const useSlideDownPromptPushCategories = () => {
  const sessionChecked = useSelector((state) => state.user.sessionChecked);
  const logged = useSelector((state) => state.user.logged);
  const isAppOnboarded = useSelector((state) => state.user.isAppOnboarded);

  useEffect(() => {
    if (!sessionChecked || !logged || !isAppOnboarded || 'playwright' in window || 'zoolifeE2ETesting' in window) {
      return undefined;
    }
    const id = setTimeout(async () => {
      if (!OneSignal.Notifications.permission) {
        // It still obey the Slidedown back off logic from OneSignal
        // See more: https://documentation.onesignal.com/docs/permission-requests#slidedown-back-off-logic
        OneSignal.Slidedown.promptPushCategories();
      }
    }, 5000);
    return () => clearTimeout(id);
  }, [isAppOnboarded, logged, sessionChecked]);
};
