import { Box } from 'grommet';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { route, Router } from 'preact-router';
import { connect, useDispatch } from 'react-redux';
import { Permission } from 'zl-shared';

import AppLoader from '@/components/AppLoader';
import ContactUsModalLoader from '@/components/async/ContactUsModalLoader';
import InstallAppModalLoader from '@/components/async/InstallAppModalLoader';
import InviteModalLoader from '@/components/async/InviteModalLoader';
import AuthGuard from '@/components/Authorize/AuthGuard';
import GuestOnlyGuard from '@/components/Authorize/GuestOnlyGuard';
import HabitatsUpdater from '@/components/HabitatsUpdater';
import Header from '@/components/Header';
import Redirect from '@/components/Redirect';
import TermsAndConditions from '@/components/TermsAndConditions';
import { PRIVACY_PDF_URL, TERMS_PDF_URL } from '@/components/TermsAndConditions/constants';
import WhatsNew from '@/components/WhatsNew';
import { getRedirectURLLocalStorage, logAndGetCampaignData } from '@/helpers';
import { useSubscribeNotificationsHandler } from '@/hooks/notifications';
import { setIsRealEmail, setReferralData, setUserData } from '@/redux/slices/user/actions';
// Code-splitting is automated for `routes` directory
import Account from '@/routes/account';
import Album from '@/routes/album';
import EmailVerify from '@/routes/emailVerify';
import Favorite from '@/routes/favorite';
import Feed from '@/routes/feed';
import Gift from '@/routes/gift';
import Habitat from '@/routes/habitat';
import Habitats from '@/routes/habitats';
import Help from '@/routes/help';
import Highlights from '@/routes/highlights';
import HomeWithLayout from '@/routes/home';
import Login from '@/routes/login';
import HabitatsView from '@/routes/MapView';
import MyAlbumLayoutDetector from '@/routes/myAlbum/MyAlbumLayoutDetector';
import NotFound from '@/routes/notFound';
import Notifications from '@/routes/notifications';
import PasswordReset from '@/routes/passwordReset';
import Plans from '@/routes/plans';
import Redeem from '@/routes/redeem';
import Schedule from '@/routes/schedule';
import ScheduleMobileLayout from '@/routes/schedule/MobileLayout';
import Signup from '@/routes/signup';
import SocialLogin from '@/routes/socialLogin';
import TalkSchedule from '@/routes/talkSchedule';
import TermsAndPrivacy from '@/routes/TermsAndPrivacy';
import Welcome from '@/routes/welcome';
import { buildURL, patch, post } from '@/shared/fetch';
import { logPageViewGA } from '@/shared/ga';
import { trackMP } from '@/shared/mp';
import { useSlideDownPromptPushCategories, useUpdatePushNotificationData } from '@/shared/pushNotifications/hooks';

import IframeView from '../IframeView/IframeView';
import { LayoutDetector } from '../LayoutDetector';
import ConnectedMembersOnlyWarningModal from '../MembersOnlyWarningModal/redux/connected';
import SurveyPopup from '../modals/Survey/SurveyPopup';
import SubscriptionManager from '../Subscription/SubscriptionManager';
import AdminRouter from './AdminRouter';
import AutoReadNotificationsHandler from './AutoReadNotificationsHandler';
import PageWrapper from './PageWrapper';

const Main = ({ onRouteChange, showContactUs, showInvite, logged, timezone, showInstallApp, freeHabitats }) => {
  const dispatch = useDispatch();
  const [path, setPath] = useState();
  const isGiftCardUser = useMemo(() => {
    if (typeof document !== 'undefined') {
      const { searchParams } = new URL(window.location);
      return searchParams.get('isGiftCardUser') === 'true';
    }
    return false;
  }, []);

  useEffect(() => {
    const updateRefData = async () => {
      const campaignData = await logAndGetCampaignData();
      dispatch(setReferralData(campaignData));
    };
    updateRefData();
  }, [dispatch]);

  useEffect(() => {
    const { timeZone: clientTimezone } = Intl.DateTimeFormat().resolvedOptions();
    if (logged && timezone !== clientTimezone) {
      patch(buildURL('/users/timezone'), { timezone: clientTimezone }).catch((error) =>
        console.error('Failed to update timezone', error),
      );
    }
  }, [logged, timezone]);

  const emailVerificationHandler = useCallback(
    async (token, email, source) => {
      try {
        const { valid } = await post(buildURL('/users/emailVerification'), { token, email, source });
        if (valid) {
          dispatch(setIsRealEmail());
        }
      } catch (err) {
        console.error('email verification error: ', err);
      }
    },
    [dispatch],
  );

  const routerChangeHandler = (props) => {
    const {
      url,
      current: {
        props: { matches },
      },
    } = props;

    if (url.startsWith('/checkout-completed')) {
      const { passType, price } = matches;
      setPath(`/welcome?plan=${passType}&price=${price}`);
      return;
    }

    if (matches.emailVerificationCode && matches.verificationEmail) {
      emailVerificationHandler(matches.emailVerificationCode, matches.verificationEmail, 'link');
      return;
    }
    setPath(url);
    logPageViewGA(url);
    trackMP('page-view');

    if (typeof window !== 'undefined' && window.fbq) {
      window.fbq('track', 'PageView');
    }

    onRouteChange(props);
  };

  let afhParam;
  if (typeof window !== 'undefined' && !window.location.href.includes('socialLogin')) {
    const params = new URLSearchParams(window.location.search);
    const redirectToParam = params.get('redirect');
    afhParam = params.get('utm_afh');
    if (logged && redirectToParam) {
      route(redirectToParam, true);
    }
  }

  useEffect(() => {
    if (logged && afhParam) {
      (async (afhParam) => {
        const newPath = getRedirectURLLocalStorage();

        if (freeHabitats.includes(afhParam)) {
          route(newPath ?? '/map', true);
          return;
        }
        try {
          const url = buildURL('/users/addFreeHabitat');
          const afhResponse = await post(url, { freeHabitats: [afhParam] });
          if (afhResponse?.user) {
            dispatch(setUserData({ ...afhResponse.user, sessionChecked: true }));
          }
          logPageViewGA('/afh');
          trackMP('afh');
        } catch (err) {
          console.error('Error on afh procedure', err);
          route(newPath ?? '/map', true);
        }
      })(afhParam);
    }
  }, [logged, afhParam, freeHabitats, dispatch]);

  useUpdatePushNotificationData();
  useSlideDownPromptPushCategories();

  useSubscribeNotificationsHandler();

  const routes = (
    <Box fill className="calculated-full-height">
      <Router onChange={routerChangeHandler}>
        {/* moving to Webflow eliminate the use of our home page */}
        <Redirect path="/" to="/home" />

        <Redirect path="/map" to="/home" />
        <AuthGuard path="/home" title="Home" permission={Permission.Map.View} redirectTo="/login">
          <HomeWithLayout />
        </AuthGuard>

        <GuestOnlyGuard path="/prices" exact title="Pricing" redirectRoute="/plans">
          <Header />
          <PageWrapper>
            <Plans />
          </PageWrapper>
        </GuestOnlyGuard>

        <SocialLogin path="/socialLogin" />
        <Redirect path="/checkout-completed" to={path} />
        <Redirect path="/checkout-cancelled" to="/plans" />

        <GuestOnlyGuard path="/login" redirectRoute="/map" title="Login">
          <Login />
        </GuestOnlyGuard>

        <GuestOnlyGuard path="/signup" title="Sign Up" redirectRoute={isGiftCardUser ? '/redeem' : '/map'}>
          <Signup />
        </GuestOnlyGuard>

        <Login path="/login/token/:token" title="Login" />

        <GuestOnlyGuard path="/help" title="Help" fallback={<Help />}>
          <Help />
        </GuestOnlyGuard>

        <PasswordReset path="/passwordReset" title="Reset Password" />

        {/* display 404 instead of Unatuhorized message
            we don't want our viewers to know about this route
            and still redirect unauthenticated users to /login page
            this redirection is there not to confuse our admins */}
        <AuthGuard path="/admin/:*" title="Admin" adminOnly fallback={<NotFound />}>
          <AdminRouter />
        </AuthGuard>

        <AuthGuard path="/highlights" title="Highlights" permission={Permission.Map.View} redirectTo="/login">
          <Highlights />
        </AuthGuard>

        <AuthGuard path="/feed" title="Feed" adminOnly fallback={<NotFound />}>
          <Feed />
        </AuthGuard>

        <AuthGuard path="/habitats" title="Habitats" permission={Permission.Map.View} redirectTo="/login">
          <Habitats />
        </AuthGuard>

        <AuthGuard path="/welcome" redirectTo="map" permission={Permission.Welcome.View}>
          <Welcome />
        </AuthGuard>

        <Habitat path="/h/:zooName/:habitatSlug" skipTitle />

        <AuthGuard path="/notifications" title="Notifications" redirectTo="/login" permission={Permission.Map.View}>
          <Notifications />
        </AuthGuard>
        <AuthGuard
          path="/schedule"
          title="Schedule"
          publicPath="/talk-schedule"
          permission={Permission.Schedule.View}
          redirectTo="/talk-schedule"
        >
          <LayoutDetector desktop={<TalkSchedule />} mobile={<ScheduleMobileLayout />} title="Events" />
        </AuthGuard>
        <GuestOnlyGuard path="/talk-schedule" title="Schedule" redirectRoute="/schedule">
          <LayoutDetector desktop={<TalkSchedule />} mobile={<ScheduleMobileLayout title="Events" />} />
        </GuestOnlyGuard>
        <GuestOnlyGuard title="My Profile" path="/u/:userName" fallback={<MyAlbumLayoutDetector />}>
          <MyAlbumLayoutDetector />
        </GuestOnlyGuard>
        <AuthGuard path="/redeem" permission={Permission.Redeem.View} redirectTo="/login">
          <Redeem />
        </AuthGuard>
        <AuthGuard path="/emailVerification" permission={Permission.EmailVerifyInput.View} redirectTo="/signup">
          <EmailVerify />
        </AuthGuard>
        <Gift path="/gift" />
        <AuthGuard path="/favorite" permission={Permission.Favorite.Edit} redirectTo="/plans">
          <Header />
          <PageWrapper>
            <Favorite />
          </PageWrapper>
        </AuthGuard>
        <AuthGuard path="/account/:activeTab" permission={Permission.Profile.Edit}>
          <Account />
        </AuthGuard>
        <AuthGuard path="/plans" permission={Permission.Checkout.Plans} redirectTo="/login">
          <Header />
          <PageWrapper>
            <Plans />
          </PageWrapper>
        </AuthGuard>

        <TermsAndPrivacy path="/terms-and-conditions" title="Terms and Conditions" pdfLink={TERMS_PDF_URL} />
        <TermsAndPrivacy path="/privacy-policy" title="Privacy Policy" pdfLink={PRIVACY_PDF_URL} />

        <Album mediaType="photos" path="/album/photos/:photoId" />
        <Album mediaType="videos" path="/album/videos/:videoId" />

        {/* TODO remove /mapView once its updated on the torontozoo website */}
        {/* Keep for backwards compatibility for partners using this url */}
        <IframeView linkTarget="_blank" path="/mapView" key="habitats" showTrailers>
          <HabitatsView />
        </IframeView>
        <IframeView linkTarget="_blank" path="/embed/map-view" key="embed-habitats" showTrailers>
          <HabitatsView embed />
        </IframeView>
        <IframeView path="/embed/schedules-view" key="embed-schedule">
          <Schedule />
        </IframeView>
        <IframeView linkTarget="_parent" path="/embed/price-cards" key="embed-prices">
          <SubscriptionManager showFreemium isPublicPage />
        </IframeView>

        {/* NOTE: NotFound need to be at the end, on first render path is undefined */}
        {path === undefined ? <AppLoader path=":*" /> : <NotFound path=":*" />}
      </Router>

      <TermsAndConditions />
      <ContactUsModalLoader isOpen={showContactUs} />
      <InstallAppModalLoader isOpen={showInstallApp} />
      <SurveyPopup />
      <InviteModalLoader isOpen={showInvite} />
      <HabitatsUpdater />
      <WhatsNew />
      <ConnectedMembersOnlyWarningModal />

      <AutoReadNotificationsHandler />
    </Box>
  );

  return routes;
};

export default connect(
  ({
    user: {
      logged,
      timezone,
      subscription: { freeHabitats },
      lastLogin,
    },
    modals: {
      contactus: { isOpen: showContactUs },
      installApp: { isOpen: showInstallApp },
      invite: { isOpen: showInvite },
    },
  }) => ({
    showContactUs,
    showInstallApp,
    showInvite,
    logged,
    timezone,
    lastLogin,
    freeHabitats,
  }),
)(Main);
