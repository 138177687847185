import { queryOptions } from '@tanstack/react-query';

import { buildURL, callDelete, patch, post } from '@/shared/fetch';

import { getQueryFn } from '../utils';
import { HabitatAnimal, HabitatAPIResponse, HabitatCurrentOrUpcomingSchedule, HabitatListAPIResponse } from './types';

export const getAllHabitatsQueryOptions = () =>
  queryOptions({
    queryKey: ['habitats', 'list'],
    queryFn: getQueryFn<HabitatListAPIResponse>('/habitats/all'),
    select: (data) => data.habitats,
  });

export const getHabitatQueryOptions = (slug: string | undefined, zooSlug: string | undefined) =>
  queryOptions({
    queryKey: ['habitats', 'detail', zooSlug, slug],
    queryFn: getQueryFn<HabitatAPIResponse>(`/zoos/${zooSlug}/habitats/${slug}`),
    select: (data) => data.habitat,
    enabled: Boolean(slug && zooSlug),
  });

export const getHabitatSchedulesOptions = (
  habitatId: string | undefined,
  params: { from?: Date; to?: Date; limit?: number },
) =>
  queryOptions({
    enabled: !!habitatId,
    queryKey: ['habitat-schedules', 'list', habitatId, params],
    queryFn: getQueryFn<HabitatCurrentOrUpcomingSchedule>(`/habitats/${habitatId}/schedules`, params),
  });

export const followHabitatMutationFn = (habitatId: string) => post<void>(buildURL('/habitats/favorite'), { habitatId });

export const unfollowHabitatMutationFn = (habitatId: string) =>
  callDelete<void>(buildURL('/habitats/favorite'), { habitatId });

export const createHabitatAnimalMutationFn = ({
  habitatId,
  animal,
}: {
  habitatId: string;
  animal: Omit<HabitatAnimal, '_id'>;
}) => post<HabitatAnimal>(buildURL(`/admin/habitats/${habitatId}/animals`), animal);

export const updateHabitatAnimalMutationFn = ({
  habitatId,
  animal,
}: {
  habitatId: string;
  animal: HabitatAnimal & { index: number };
}) => patch<HabitatAnimal>(buildURL(`/admin/habitats/${habitatId}/animals/${animal._id}`), animal);

export const deleteHabitatAnimalMutationFn = ({ habitatId, animalId }: { habitatId: string; animalId: string }) =>
  callDelete<void>(buildURL(`/admin/habitats/${habitatId}/animals/${animalId}`));
