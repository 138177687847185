import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Collapsible } from 'grommet';
import { useMemo, useState } from 'preact/hooks';

import { AnnouncementWithViewed } from '@/queries/announcements/types';

import AnnouncementItem from './AnnouncementItem';

const Announcements = ({ data }: { data?: AnnouncementWithViewed[] }) => {
  const [expanded, setExpanded] = useState(false);

  const [newAnnouncements, seenAnnouncements] = useMemo(() => {
    return (data ?? [])?.reduce(
      (acc, item) => {
        if (item.isViewed) {
          acc[1].push(item);
        } else {
          acc[0].push(item);
        }
        return acc;
      },
      [[], []] as [AnnouncementWithViewed[], AnnouncementWithViewed[]],
    );
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <div className="mb-2 empty:hidden">
      {seenAnnouncements.length > 0 && (
        <>
          <div className="mx-4 mb-2 flex justify-end">
            <button
              type="button"
              className="preflight preflight-button text flex items-center gap-1 text-xs text-grey-3"
              onClick={() => setExpanded(!expanded)}
            >
              <span>{expanded ? 'Hide read announcements' : 'Show read announcements'}</span>
              <FontAwesomeIcon icon={faChevronDown} className="transition-transform" rotation={expanded ? 180 : undefined} />
            </button>
          </div>
          <Collapsible open={expanded}>
            <ul className={clsx('preflight preflight-ul flex flex-col gap-2 px-2', newAnnouncements.length > 0 && 'mb-2')}>
              {seenAnnouncements.map((item) => (
                <li key={item._id}>
                  <AnnouncementItem item={item} />
                </li>
              ))}
            </ul>
          </Collapsible>
        </>
      )}
      {newAnnouncements.length > 0 && (
        <ul className="preflight preflight-ul flex flex-col gap-2 px-2">
          {newAnnouncements.map((item) => (
            <li key={item._id}>
              <AnnouncementItem item={item} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Announcements;
