import { useMemo } from 'preact/hooks';
import { route, useRouter } from 'preact-router';

type Query = Record<string, string | null | undefined>;

const mergeParams = (params: URLSearchParams, query: Query) => {
  Object.entries(query).forEach(([key, value]) => {
    if (value == null) {
      params.delete(key);
      return;
    }
    params.set(key, value);
  });
  return params;
};

export const getUrl = (path: string | null | undefined, query: Query = {}) => {
  const finalPath = path ?? window?.location.pathname ?? '';
  const params = new URLSearchParams(window?.location.search);
  mergeParams(params, query);
  return `${finalPath}?${params.toString()}`;
};

// TODO: if we don't need the `URLSearchParams` object, we can use the `matches` object in the `useRouter` hook
export const useQueryParams = () => {
  const [{ url }] = useRouter();
  // this is a hack to make sure the query params are always up to date when the url changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => new URLSearchParams(window?.location.search), [url]);
};

export const setQueryParams = (query: Query, replace = false) => {
  const params = new URLSearchParams(window.location.search);
  const queryString = mergeParams(params, query).toString();
  route(`${window.location.pathname}${queryString ? `?${queryString}` : ''}`, replace);
};
